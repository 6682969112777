//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { UserModel } from '@/modules/auth/user-model';
import { mapGetters, mapActions } from 'vuex';
import { IamPermissions } from '@/modules/iam/iam-permissions';
import Roles from '@/security/roles';
import { i18n } from '@/i18n';
// import moment from 'moment';

const { fields } = UserModel;

export default {
  name: 'app-iam-list-table',
  props: ['userType'],

  mounted() {
    if (this.userType == 'client') {
      this.columns.splice(4, 1)
    }
    this.doMountTable(this.$refs.table);
  },

  data() {
    return {
      selected: [],
      Pagination: {
        rowsPerPage: 10
      },
      columns: [
        {
          name: 'avatarsIam',
          field: 'avatarsIam',
          label: i18n('user.fields.avatars'), 
          align: 'center',
          // required: true,
        },
        {
          name: 'fullName',
          field: 'fullName',
          label: i18n('user.fields.fullName'),
          align: 'center',
          // sortable: true,
        },
        {
          name: 'email',
          field: 'email',
          label: i18n('user.fields.email'), 
          align: 'center',
          // format: (val) => `${val}`,
          // required: true,
          // sortable: true,
        },
        {
          name: 'phoneNumber',
          field: 'phoneNumber',
          label: i18n('user.fields.phoneNumber'),
          align: 'center',
          // sortable: true,
        },        
        {
          name: 'roles',
          field: 'roles',
          label: i18n('user.fields.roles'),
          align: 'center',
        },
        {
          name: 'createdAt',
          field: 'createdAt',
          label: i18n('user.fields.createdAt'),
          align: 'center',
          // sortable: true,
        },
        {
          name: 'status',
          field: 'disabledAsStatus',
          label: i18n('user.fields.status'),
          align: 'center',
          // sortable: true,
        },
        {
          name: 'action',
          field: 'action',
          label:  i18n('common.action'),
          align: 'center',
        },
      ],
    }
  },

  computed: {
    ...mapGetters({
      rows: 'iam/list/rows',
      count: 'iam/list/count',
      loading: 'iam/list/loading',
      pagination: 'iam/list/pagination',
      isMobile: 'layout/isMobile',
      currentUser: 'auth/currentUser',
      paginationLayout: 'layout/paginationLayout',
      is_screen_xs: 'layout/is_screen_xs',
    }),

    hasPermissionToEdit() {
      return new IamPermissions(this.currentUser).edit;
    },

    fields() {
      return fields;
    },
  },

  methods: {
    ...mapActions({
      doChangeSort: 'iam/list/doChangeSort',
      doChangePaginationCurrentPage: 'iam/list/doChangePaginationCurrentPage',
      doChangePaginationPageSize: 'iam/list/doChangePaginationPageSize',
      doMountTable: 'iam/list/doMountTable',
    }),
    i18n(key, args) {
      return i18n(key, args);
    }, 
    
    roleDescriptionOf(roleId) {
      return Roles.descriptionOf(roleId);
    },

    roleLabelOf(roleId) {
      return Roles.labelOf(roleId);
    },

    presenter(row, fieldName) {
      return UserModel.presenter(row, fieldName);
    },
  },
};
